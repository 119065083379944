export function number_format (number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
  }
  


  export function abvNum (n: any) {
    // if (!n) return 0;
    // var newValue = n;
    // if (n >= 1000) {
    //   var suffixes = ["", "K", "M", "B", "T"];
    //   var suffixNum = Math.floor(("" + n).length / 3);
    //   var shortValue: any = '';
    //   for (var precision = 2; precision >= 1; precision--) {
    //     shortValue = parseFloat((suffixNum != 0 ? (n / Math.pow(1000, suffixNum)) : n).toPrecision(precision));
    //     var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
    //     if (dotLessShortValue.length <= 2) { break; }
    //   }
    //   if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
    //   newValue = shortValue + suffixes[suffixNum];
    // }
    // return newValue;

    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";


  }

