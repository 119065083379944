import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import { isEmpty } from '../../../../helper';

type DonutChartProps = {
  labels: string[];
  series: number[];
  colors: ApexFill['colors'] | string[];
}


function DonutChart({ colors, labels, series}: DonutChartProps) {
  let num_series = series.map((s) => +s);
  
  const options: ApexOptions = {
    series: num_series,
    labels: labels,
    chart: {
      type: 'donut'
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      pie: {
        donut: {
          size: "20px",
          labels: {
            total: {
              show: true
            },
            name: {
              show: true,
              fontSize: "12px",
              fontWeight: "500",
              color: "#000"
            }
          }
        }
      }
    },
    legend: {
      show: (!colors || isEmpty(colors)) ? true : false
    },
    fill: {
      colors: colors?.map((color) => `#${color}`) 
    }
  }

  

  const isValidDonutOptions = (l: typeof labels, s: typeof series) => {
    console.log(labels.length, series.length, labels, series)
    if (isEmpty(labels) || isEmpty(series) || labels.length !== series.length) 
      return false;
    return true;
  }

  return (
    <div className="container d-flex justify-content-center">
      <div className="row">
        {
          isValidDonutOptions(labels, series) ? (
            <Chart options={options} series={options.series} type="donut" />
          ):
          (
            <h2 className="">Chart Unavailable</h2>
          )
        }
      </div>
    </div>
  );
   
}


export { DonutChart }