/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import * as actions from "./_redux/Actions";
import * as dash_actions from "../../pages/dashboard/_redux/Actions";
import * as camp_actions from "../campaigns/_redux/campaign/Actions";

import { InfluencerStatsTopWidget, } from '../../../_metronic/partials/widgets'
import StatSummary from './components/StatSummary';
import StatsSocial from './components/StatsSocial';
import StatsCampaign from './components/StatsCampaign';
import StatsContact from './components/StatsContact';
import StatsUpload from './components/StatsUpload';
import StatsAudience from './components/StatsAudience';
import Skeleton from 'react-loading-skeleton';

const tabs = [
  { label: 'Summary', icon: 'chart-simple.png', activeIcon: 'chart-line-up.png' },
  { label: 'Social', icon: 'hashtag.png', activeIcon: 'hashtag-active.png' },
  { label: 'Campaigns', icon: 'table-columns.png', activeIcon: 'table-columns-active.png' },
  { label: 'Audience', icon: 'people.png', activeIcon: 'people-active.png' },
  { label: 'Contact', icon: 'address-book.png', activeIcon: 'address-book-active.png' },
  { label: 'Uploads', icon: 'post-icon.png', activeIcon: 'image-active.png' },
]

const InfluencerStats = (props: any) => {
  const classnames = (...classes: any) => classes.filter(Boolean).join(' ');
  const [selectedTab, setTab] = useState<number>(0);
  const [campaigns_activity, set_act] = useState<any>([]);
  const [click_activity, set_clicks] = useState<any>([]);
  const dispatch = useDispatch<any>();
  const history = useHistory();

  const { influencerDetails, demographics, user, summary,campaigns,  socials, contact, campaignStats } = useSelector(
    (state: any) => ({
      influencerDetails: state.influencer.influencerDetails,
      user: state.auth.user,
      summary: state.influencer.summary,
      socials: state.influencer.socials,
      demographics: state.influencer.demographics,
      campaigns: state.influencer.campaigns,
      contact: state.influencer.contact,
      campaignStats: state.campaign.campaignStats,
    })
  );


  const id = user.PublisherId;

  useEffect(() => {
    dispatch(actions.reset_info());
 
    dispatch(actions.fetchAllData('influencer_details', `?influencer_id=${id}`));
    dispatch(actions.fetchAllData('socials', `?influencer_id=${id}`));
    dispatch(actions.fetchAllData('summary', `?influencer_id=${id}`));
    dispatch(actions.fetchAllData('demographics', `?influencer_id=${id}`));
    dispatch(actions.fetchAllData('accounts_summary', `?influencer_id=${id}`));
    dispatch(actions.fetchAllData('file', `?influencer_id=${id}`));
  }, []);


  useEffect(() => {

    if (influencerDetails) {
      dispatch(actions.fetchAllData('influencer_campaigns', `?influencer_id=${influencerDetails.Id}`));
      dispatch(actions.fetchAllData('contact', `?address_url=${influencerDetails.Address.href}`));
    }
    
  }, [influencerDetails])


  useEffect(() => {
    var acts: any = []
    var actsC: any = []
    if (campaigns && campaigns.CampaignCollection.length > 0) {
      campaigns.CampaignCollection.forEach((element: any) => {
        dispatch(dash_actions.fetchCampaignStats('influencer_campaigns', element.PublisherInCampaign.CampaignId)).then((resp: any) => {
          // console.log("UPDATEEEEEE", resp)
          if (JSON.stringify(resp) !== '{}' || !resp) {
            // acts.push(resp);
            set_act((prevState: any) => [...prevState, resp])
      
          }
        });

        dispatch(camp_actions.fetchStuff('clicks', element.PublisherInCampaign.CampaignId, 'true')).then((respC: any) => {
          // console.log("UPDATEEEEEE", respC)
          // actsC.push(actsC);
          if (JSON.stringify(respC) !== '{}' || !respC) {
            // actsC.push(respC);
            set_clicks((prevState: any) => [...prevState, respC])
          }
        });

      });

      // set_act(acts);
      // set_clicks(actsC);
      
      
    }
  }, [campaigns])

  return (
    <>
      <div className='row g-5 mt-0'>
        <div className='col-xl-12'>
          {
            !influencerDetails ?
            <div>
              <Skeleton height={70} count={3} />
            </div>
            :
            <InfluencerStatsTopWidget 
            click_activity = {click_activity}
            campaigns_activity = {campaigns_activity}
            influencerDetails={influencerDetails} campaignStats={campaignStats} />
          }
          <div className="card mt-4 p-6">
            <div className="tabs" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
              {tabs.map((tab, index) => (
                <button className={classnames((selectedTab === index ? "btn-primary active text-white" : "text-gray-600 btn-link"), "btn rounded-pill px-4 mx-2 mx-lg-4")}
                  onClick={() => setTab(index)} key={index}
                >
                  <img src={toAbsoluteUrl(`/media/cpg/${selectedTab === index ? tab.activeIcon : tab.icon}`)} style={{ height: '14px', marginRight: '4px' }} />
                  {tab.label}
                </button>
              ))}
            </div>
            {selectedTab === 0 && <StatSummary 
            click_activity = {click_activity}
            campaigns_activity = {campaigns_activity}
            summary = {summary} campaigns = {campaigns} influencerDetails={influencerDetails}/>}
            {selectedTab === 1 && <StatsSocial socials = {socials} />}
            {selectedTab === 2 && <StatsCampaign campaigns = {campaigns} />}
            {selectedTab === 3 && <StatsAudience demographics = {demographics} />}
            {selectedTab === 4 && <StatsContact contact = {contact} />}
            {selectedTab === 5 && <StatsUpload />}
          </div>
        </div>
      </div>
    </>
  )
}


export { InfluencerStats }
