import React from 'react';

const SearchInput = (props: any) => {
  return (
    <div style={{ position: 'relative' }}>
      <input
        style={{
          height: '28px',
          width: '220px',
          left: '1410px',
          top: '146px',
          borderRadius: '16px',
          padding: '24px 16px 24px 16px',
          border: 'none'
        }}
        type="text"
        placeholder={props.placeholder ? props.placeholder : "Search Influencers"}
      />
      <i
        className="fa fa-search"
        style={{
          position: 'absolute',
          right: '16px',
          top: '50%',
          transform: 'translateY(-50%)'
        }}
      />
    </div>
  );
};

export default SearchInput;
