import { useState, ChangeEvent } from 'react';
import { Modal, Alert } from 'react-bootstrap-v5'
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../_redux/Actions';
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { DonutChart } from '../../../../_metronic/partials/widgets';
import DetailCardThumbnail from '../../../../asset/images/uploadCardThumbnail.png';

const inputClassName = "appearance-none w-100 d-block px-3 h-48px border bg-gray-200 rounded placeholder-gray-500 fs-5";
const styles = {
  height: '60px',
  marginBottom: '20px',
  backgroundColor: '#F4F4FA',
  placeholderColor: '#000000',
}

const tableForms: any = {
  fbIgOrganic: [
    {
      required: false,
      key: 'influencerName',
      type: 'text',
      label: 'Influencer Name',
    },
    {
      required: false,
      key: 'platform',
      type: 'select',
      label: 'Platform',
      options: ['Select Platform', 'IG', 'TT', 'YT']
    },
    {
      required: false,
      type: 'select',
      key: 'postType',
      label: 'Post type',
      options: ['Select type', 'Reel', 'Post', 'Video', 'Story', 'Carousel'],
    },
    {
      required: true,
      type: 'number',
      key: 'numberOfPosts',
      label: 'Number of posts',
    },
    {
      required: true,
      type: 'text',
      key: 'verticals',
      label: 'Verticals',
    },
    {
      required: true,
      type: 'text',
      key: 'niche',
      label: 'Niche',
    },
    {
      required: true,
      type: 'text',
      key: 'coreAudience',
      label: 'Core Audience',
    },
    {
      required: true,
      type: 'text',
      key: 'followers',
      label: 'Followers',
    },
    {
      required: true,
      type: 'text',
      key: 'estimatedReach',
      label: 'Estimated Reach',
    },
    {
      required: true,
      type: 'text',
      key: 'costPerDeliverable',
      label: 'Cost per Deliverable',
    },
    {
      required: true,
      type: 'text',
      key: 'feePaid',
      label: 'Cost/Fee paid',
    },
    {
      required: true,
      type: 'text',
      key: 'paidUsage',
      label: 'Paid Usage/Terms',
    },

    {
      required: true,
      type: 'text',
      key: 'messaging',
      label: 'Messaging',
    },
    {
      required: true,
      type: 'text',
      key: 'er',
      label: 'ER - 5%',
    },
    {
      required: false,
      type: 'text',
      key: 'linkToContent',
      label: 'Link to content posted',
    },
    {
      required: false,
      type: 'text',
      key: 'username',
      label: 'Username',
    },
    {
      required: false,
      type: 'date',
      key: 'mediaCreatedDate',
      label: 'Media created date',
    },
    {
      required: false,
      type: 'text',
      key: 'mediaURL',
      label: 'Media URL',
    },
    {
      required: false,
      type: 'text',
      key: 'mediaThumbnailURL',
      label: 'Media thumbnail URL',
    },
    {
      required: false,
      type: 'text',
      key: 'mediaCaption',
      label: 'Media caption',
    },
    {
      required: false,
      type: 'text',
      key: 'mediaType',
      label: 'Media type ',
    },
    {
      required: false,
      type: 'text',
      key: 'mediaLikes',
      label: 'Media likes',
    },
    {
      required: false,
      type: 'text',
      key: 'mediaComments',
      label: 'Media comments',
    },
    {
      required: false,
      type: 'text',
      key: 'mediaEngagements',
      label: 'Media engagements',
    },
    {
      required: false,
      type: 'text',
      key: 'mediaImpressions',
      label: 'Media impressions',
    },
    {
      required: false,
      type: 'text',
      key: 'mediaReach',
      label: 'Media reach',
    },
    {
      required: false,
      type: 'text',
      key: 'mediaUniqueSaves',
      label: 'Media unique saves',
    },
    {
      required: false,
      type: 'text',
      key: 'videoViews',
      label: 'Video views',
    },
    {
      required: false,
      type: 'text',
      key: 'reelComments',
      label: 'Reel comments',
    },
    {
      required: false,
      type: 'text',
      key: 'reelLikes',
      label: 'Reel likes',
    },
    {
      required: false,
      type: 'text',
      key: 'reelPlays',
      label: 'Reel plays',
    },
    {
      required: false,
      type: 'text',
      key: 'reelSavedTimes',
      label: 'Reel saved times',
    },
    {
      required: false,
      type: 'text',
      key: 'reelSharedTimes',
      label: 'Reel shared times',
    },
    {
      required: false,
      type: 'text',
      key: 'reelTotalInteractions',
      label: 'Reel total interactions',
    },
    {
      required: false,
      type: 'text',
      key: 'cpc',
      label: 'CPC',
    },
    {
      required: false,
      type: 'text',
      key: 'cpm',
      label: 'CPM',
    },
    {
      required: false,
      type: 'text',
      key: 'cpe',
      label: 'CPE',
    },
  ],
  tiktokOrganic: [
    {
      required: false,
      key: 'influencerName',
      type: 'text',
      label: 'Influencer Name',
    },
    {
      required: false,
      key: 'platform',
      type: 'select',
      label: 'Platform',
      options: ['Select Platform', 'IG', 'TT', 'YT']
    },
    {
      required: false,
      type: 'select',
      key: 'postType',
      label: 'Post type',
      options: ['Select type', 'Reel', 'Post', 'Video', 'Story', 'Carousel'],
    },
    {
      required: true,
      type: 'text',
      key: 'numberOfPosts',
      label: 'Number of posts',
    },
    {
      required: true,
      type: 'text',
      key: 'verticals',
      label: 'Verticals',
    },
    {
      required: true,
      type: 'text',
      key: 'niche',
      label: 'Niche',
    },
    {
      required: true,
      type: 'text',
      key: 'coreAudience',
      label: 'Core Audience',
    },
    {
      required: true,
      type: 'text',
      key: 'followers',
      label: 'Followers',
    },
    {
      required: true,
      type: 'text',
      key: 'estimatedReach',
      label: 'Estimated Reach',
    },
    {
      required: true,
      type: 'text',
      key: 'costPerDeliverable',
      label: 'Cost per Deliverable',
    },
    {
      required: true,
      type: 'text',
      key: 'feePaid',
      label: 'Cost/Fee paid',
    },
    {
      required: true,
      type: 'text',
      key: 'paidUsage',
      label: 'Paid Usage/Terms',
    },
    {
      required: true,
      type: 'text',
      key: 'messaging',
      label: 'Messaging',
    },
    {
      required: true,
      type: 'text',
      key: 'er',
      label: 'ER - 5%',
    },
    {
      required: false,
      type: 'text',
      key: 'linkToContent',
      label: 'Link to content posted',
    },
    {
      required: false,
      type: 'date',
      key: 'datePublished',
      label: 'Date published',
    },
    {
      required: false,
      type: 'text',
      key: 'videoViews',
      label: 'Video views',
    },
    {
      required: false,
      type: 'text',
      key: 'profileViews',
      label: 'Profile views',
    },
    {
      required: false,
      type: 'text',
      key: 'likes',
      label: 'Likes',
    },
    {
      required: false,
      type: 'text',
      key: 'comments',
      label: 'Comments',
    },
    {
      required: false,
      type: 'text',
      key: 'shares',
      label: 'Shared',
    },
    {
      required: true,
      type: 'text',
      key: 'cpm',
      label: 'CPM',
    },
    {
      required: true,
      type: 'text',
      key: 'cpc',
      label: 'CPC',
    },
    {
      required: true,
      type: 'text',
      key: 'cpe',
      label: 'CPE',
    },
  ],
  youtubeOrganic: [
    {
      required: false,
      key: 'influencerName',
      type: 'text',
      label: 'Influencer Name',
    },
    {
      required: false,
      key: 'platform',
      type: 'select',
      label: 'Platform',
      options: ['Select Platform', 'IG', 'TT', 'YT']
    },
    {
      required: false,
      type: 'select',
      key: 'postType',
      label: 'Post type',
      options: ['Select type', 'Reel', 'Post', 'Video', 'Story', 'Carousel'],
    },
    {
      required: true,
      type: 'text',
      key: 'numberOfPosts',
      label: 'Number of posts',
    },
    {
      required: true,
      type: 'text',
      key: 'verticals',
      label: 'Verticals',
    },
    {
      required: true,
      type: 'text',
      key: 'niche',
      label: 'Niche',
    },
    {
      required: true,
      type: 'text',
      key: 'coreAudience',
      label: 'Core Audience',
    },
    {
      required: true,
      type: 'text',
      key: 'followers',
      label: 'Followers',
    },
    {
      required: true,
      type: 'text',
      key: 'estimatedReach',
      label: 'Estimated Reach',
    },
    {
      required: true,
      type: 'text',
      key: 'costPerDeliverable',
      label: 'Cost per Deliverable',
    },
    {
      required: true,
      type: 'text',
      key: 'feePaid',
      label: 'Cost/Fee paid',
    },
    {
      required: true,
      type: 'text',
      key: 'paidUsage',
      label: 'Paid Usage/Terms',
    },
    {
      required: true,
      type: 'text',
      key: 'messaging',
      label: 'Messaging',
    },
    {
      required: true,
      type: 'text',
      key: 'er',
      label: 'ER - 5%',
    },
    {
      required: false,
      type: 'text',
      key: 'linkToContent',
      label: 'Link to content posted',
    },
    {
      required: false,
      type: 'date',
      key: 'datePublished',
      label: 'Date published',
    },
    {
      required: false,
      type: 'text',
      key: 'watchTime',
      label: 'Watch time',
    },
    {
      required: false,
      type: 'text',
      key: 'subscribers',
      label: 'Subscribers',
    },
    {
      required: false,
      type: 'text',
      key: 'averageViewDuration',
      label: 'Average View Duration',
    },
    {
      required: false,
      type: 'text',
      key: 'averagePercentageViewed',
      label: 'Average Percentage Viewed',
    },
    {
      required: false,
      type: 'text',
      key: 'clickThroughRate',
      label: 'Click Through Rate',
    },
    {
      required: true,
      type: 'text',
      key: 'uniqueViewers',
      label: 'Unique Viewers',
    },
  ],
  actualDeliveryOrganic: [
    {
      required: false,
      key: 'phase',
      type: 'select',
      label: 'Phase',
      options: ['Select Phase', 'Phase 1', 'Phase 2', 'Phase 3',]
    },
    {
      required: false,
      key: 'influencer',
      type: 'text',
      label: 'Influencer',
    },
    {
      required: false,
      type: 'select',
      key: 'deliverable',
      label: 'Deliverable',
      options: ['Select type', 'Reel', 'Post', 'Video', 'Story', 'Carousel'],
    },
    {
      required: false,
      type: 'text',
      key: 'linkToContent',
      label: 'Link to content posted',
    },
    {
      required: false,
      type: 'date',
      key: 'datePublished',
      label: 'Date published',
    },
    {
      required: true,
      type: 'number',
      key: 'impressions',
      label: 'Impressions',
    },
    {
      required: true,
      type: 'number',
      key: 'videoViews',
      label: 'Video views',
    },
    {
      required: false,
      type: 'text',
      key: 'reach',
      label: 'Reach',
    },
    {
      required: false,
      type: 'text',
      key: 'likes',
      label: 'Likes',
    },
    {
      required: false,
      type: 'text',
      key: 'comments',
      label: 'Comments',
    },
    {
      required: false,
      type: 'text',
      key: 'saves',
      label: 'Saves',
    },
    {
      required: false,
      type: 'text',
      key: 'reaction',
      label: 'Reaction',
    },
    {
      required: false,
      type: 'text',
      key: 'shares',
      label: 'Shares',
    },
    {
      required: false,
      type: 'text',
      key: 'interactions',
      label: 'Interactions',
    },
    {
      required: true,
      type: 'text',
      key: 'totalEngagements',
      label: 'Total Engagements',
    },
    {
      required: true,
      type: 'text',
      key: 'linkClicks',
      label: 'Link Clicks',
    },
    {
      required: false,
      type: 'select',
      key: 'ChannelBreakdown',
      label: 'Channel Breakdown',
      options: ['Select Channel', 'Instagram', 'TikTok', 'Facebook', 'Youtube'],
    },

  ],
  estimatedDeliveryOrganic: [
    {
      required: false,
      key: 'phase',
      type: 'select',
      label: 'Phase',
      options: ['Select Phase', 'Phase 1', 'Phase 2', 'Phase 3',]
    },
    {
      required: false,
      key: 'influencer',
      type: 'text',
      label: 'Influencer',
    },
    {
      required: false,
      type: 'select',
      key: 'deliverable',
      label: 'Deliverable',
      options: ['Select deliverable', 'Reel', 'Post', 'Video', 'Story', 'Carousel'],
    },
    {
      required: false,
      type: 'number',
      key: 'quantity',
      label: 'Quantity',
    },
    {
      required: false,
      type: 'text',
      key: 'linkToProfile',
      label: 'Link to profile',
    },
    {
      required: false,
      type: 'select',
      key: 'vertical',
      label: 'Vertical',
      options: ['Select vertical', 'Lifestyle', 'Educational', 'Health & Wellness', 'Humour', 'Entertainment'],
    },
    {
      required: false,
      type: 'text',
      key: 'niche',
      label: 'Niche',
    },
    {
      required: false,
      type: 'text',
      key: 'coreAudienceDemo',
      label: 'Core Audience Demo',
    },
    {
      required: true,
      type: 'number',
      key: 'socialFollowing',
      label: 'Social following',
    },
    {
      required: false,
      type: 'text',
      key: 'estimatedReach',
      label: 'Estimated Reach',
    },
    {
      required: false,
      type: 'text',
      key: 'estimatedImpression',
      label: 'Estimated Impression',
    },
    {
      required: false,
      type: 'text',
      key: 'estimatedER',
      label: 'Estimated ER',
    },
    {
      required: false,
      type: 'number',
      key: 'costPerDeliverable',
      label: 'Cost Per Deliverable',
    },
    {
      required: false,
      type: 'number',
      key: 'totalInvestment',
      label: 'Total Investment',
    },
    {
      required: false,
      type: 'text',
      key: 'paidUsage',
      label: 'Paid Usage/Terms',
    },
    {
      required: false,
      type: 'text',
      key: 'consolidatedList',
      label: 'Consolidated List',
    },
    {
      required: false,
      type: 'text',
      key: 'influencerVertical',
      label: 'Influencer Vertical',
    },
    {
      required: false,
      type: 'select',
      key: 'niche',
      label: 'Niche',
      options: ['Select niche', 'Lifestyle', 'Educational', 'Health & Wellness', 'Humour', 'Entertainment'],
    },

    {
      required: false,
      type: 'select',
      key: 'ChannelBreakdown',
      label: 'Channel Breakdown',
      options: ['Select Channel', 'Instagram', 'TikTok', 'Facebook', 'Youtube'],
    },
    {
      required: false,
      type: 'select',
      key: 'costBreakdown',
      label: 'Cost Breakdown',
      options: ['Select Channel', 'Instagram', 'TikTok', 'Facebook', 'Youtube'],
    },
    {
      required: false,
      type: 'text',
      key: 'ratio',
      label: 'Ratio',
    },
    {
      required: false,
      type: 'text',
      key: 'qty',
      label: 'Qty',
    },
    {
      required: false,
      type: 'text',
      key: 'totalCost',
      label: 'Total cost',
    },
    {
      required: false,
      type: 'text',
      key: 'costPer',
      label: 'Cost per',
    },
  ]
}

const ddOptions: any = [
  { value: 'fbIgOrganic', key: 'instagram' },
  { value: 'tiktokOrganic', key: 'tiktok' },
  { value: 'youtubeOrganic', key: 'youtube' },
];

export default function StatsUpload() {
  const dispatch: any = useDispatch()
  const [show, setShow] = useState(false);
  const [show2, setModal2] = useState(false);
  const [campaign, setCampaign] = useState('');
  const [platform, setPlatform] = useState('');
  const [fieldly, setFieldly] = useState(12);
  const [extractedData, setExtractedData] = useState<any[]>([]);
  const [importantFields, SetImportantFields] = useState<any>({
    comments: '',
    likes: '',
    shares: '',
    views: '',
    saves: '',
    impressions: '',
  })
  const [impFieldsVal, setImpFieldsVal] = useState([0, 0, 0, 0, 0, 0]);
  const [impFieldsKeys] = useState(['Comments', 'Likes', 'Shares', 'Views', 'Saves', 'Impressions']);
  const [files, setFiles] = useState<any>(null);

  const { campaigns, influencerDetails } = useSelector(
    (state: any) => ({
      campaigns: state.influencer.campaigns,
      influencerDetails: state.influencer.influencerDetails,
    })
  );

  const mainString = 'comment like share views  save';
  const colors = ['008ffb', '00e396', 'ffb01a', 'ff4560', '000', '00e396'];

  const toggleModal = () => setShow(!show);
  const toggleModal2 = () => setModal2(!show2)
  const FromModal1to2 = () => {
    setShow(false);
    setModal2(true);
  }
  const FromModal2to1 = () => {
    setModal2(false);
    setShow(true);
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles(e.target.files);
    }
  };

  const splitWords = (word: string) => {
    const splif = word.split('_');
    return splif.join(' ');
  }

  const saveStatScreenshot = () => {
    const payload: any = new FormData();
    const imgArr: any = [];
    Object.keys(files).map((_, index) => imgArr.push(files[index]));
    imgArr.map((ig: any) => {
      payload.append('image[]', ig);
    })
    payload.append('campaign_id', campaign)
    payload.append('influencer_id', influencerDetails.Id)
    dispatch(actions.saveScreenshot(payload)).then((data: any) => {
      console.log('');
    }).catch((error: any) => {
      console.log('error: ', error);
      toast.error('Error occured while saving screenshot');
    })
  }

  const handleUploadClick = () => {
    if (!campaign || !platform) return toast.error('Please complete the form to continue');
    if (!files) return toast.error('Please select screenshots to continue');
    const payload: any = new FormData();
    const imgArr: any = [];
    Object.keys(files).map((_, index) => imgArr.push(files[index]));
    imgArr.map((ig: any) => {
      payload.append('image[]', ig);
    })
    dispatch(actions.uploadScreenshot(payload)).then((data: any) => {
      if (data?.data) {
        const impfClone: any = importantFields;
        const properExtracts: any = [];
        data.data.map((field: any) => {
          if (field.data_point && field.data_point.length >= 3) {
            properExtracts.push(field);
            if (impfClone.hasOwnProperty(field.data_point)) {
              impfClone[field.data_point] = field.value || '';
            }
          }
        })
        FromModal1to2();
        setExtractedData(properExtracts);
        SetImportantFields(impfClone);
      }
    }).catch((error: any) => {
      console.log('error: ', error);
      toast.error('Error occured while uploading screenshot');
    })
  }

  const prepareEditStats = () => {
    let formKey = '';
    const body: any = {};
    ddOptions.map((option: any) => {
      if (option.key == platform) {
        formKey = option.value;
      }
    });
    body.campaign_id = campaign;
    body.type = formKey;
    const cuuForm = tableForms[formKey];
    let urep: any = [];
    cuuForm.map((item: any) => {
      let lItem = item;
      let lbel = item.label;
      extractedData.map((field: any) => {
        if (field.data_point && field.data_point.length >= 3 && lbel.includes(field.data_point)) {
          lItem.value = field.value || 'n/A';
        }
      })
      lbel = item.key;
      Object.keys(importantFields).map((key: any) => {
        if (lbel.includes(key)) {
          lItem.value = importantFields[key] || 'n/A'
        }
      })
      if (lItem.key == 'influencerName') {
        lItem.value = influencerDetails?.PublisherName || 'n/A';
      }
      urep.push(lItem);
    });
    setTimeout(() => {
      body.data = urep;
      dispatch(actions.addCampaignStats(body)).then((res: any) => {
        saveStatScreenshot()
        toast.success('Successfully added campaign info');
        toggleModal2();
      }).catch(() => {
        toast.error('Error occured while updating campaign info');
        toggleModal2();
      });
    }, 100)
  }

  const updateExtracted = (value: any, index: any) => {
    let copyField: any = extractedData;
    copyField[index].value = value;
    setExtractedData(copyField);
    setFieldly(value);
  }

  const updateImpFields = (value: any, key: string, index: number) => {
    const cloneFields: any = importantFields;
    const cloneImpFieldsVal: any = impFieldsVal;
    cloneFields[key] = value;
    cloneImpFieldsVal[index] = value;
    console.log('cloneFields: cloneFields', cloneFields);
    SetImportantFields(cloneFields);
    setImpFieldsVal(cloneImpFieldsVal);
    setFieldly(value);
  }

  return (
    <div className="row gx-2 gy-4 mt-4">
      <div>
        <button className="btn btn-md btn-primary" onClick={toggleModal}>Upload Screenshot</button>
      </div>
      <Modal show={show} onHide={toggleModal} centered>
        <Modal.Body>
          <h3 className="mb-8 fs-2">Upload a screenshot</h3>
          <select name="campaign" id="campaign" className={inputClassName} style={styles}
            defaultValue={platform} onChange={(e) => setPlatform(e.target.value)}
          >
            <option value="">Select Platform</option>
            <option value="instagram">Instagram</option>
            <option value="tiktok">TikTok</option>
            <option value="youtube">Youtube</option>
          </select>
          <select name="platform" id="platform" className={inputClassName} style={styles}
            defaultValue={campaign} onChange={(e) => setCampaign(e.target.value)}
          >
            <option value="">Select Campaign</option>
            {campaigns && campaigns?.CampaignCollection.map((campaign: any, index: number) => (
              <option value={campaign?.PublisherInCampaign?.Id} key={index}>{campaign?.PublisherInCampaign?.CampaignName}</option>
            ))}
          </select>
          <div>
            <label htmlFor='upload' style={{
              border: '2px dashed #3771C8', borderRadius: '12px', backgroundColor: 'rgba(227, 233, 255, 0.5)',
              cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '24px 0'
            }}>
              <img src={toAbsoluteUrl('/media/cpg/upload.png')} style={{ width: '32px', objectFit: 'cover' }} />
              <span className="fs-5">Click here to upload your screenshot</span>
            </label>
            <input type="file" accept="image/*" id="upload" style={{ visibility: 'hidden' }} multiple onChange={handleFileChange} />
          </div>
          {files && Object.keys(files).length > 0 &&
            <div>
              {Object.keys(files).map((key: any, index: number) => (
                <span key={index} style={{ display: 'block', margin: '0 0 8px 0' }}>{files[key].name}</span>
              ))}
            </div>
          }
          <div className="d-flex align-items-center justify-content-end p-4">
            <button
              className='cancel-btn'
              type='button'
              onClick={toggleModal}
              style={{ backgroundColor: '#3771C8' }}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary rounded rounded-3'
              type='button'
              onClick={handleUploadClick}
              style={{ backgroundColor: '#3771C8' }}
            >
              <img
                className='me-2'
                style={{ width: '16px', objectFit: 'cover' }}
                src={toAbsoluteUrl('/media/cpg/rocket-takeoff.png')}
                alt='l'
              />
              Upload
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show2} onHide={toggleModal2} centered size="lg">
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <img src={toAbsoluteUrl('/media/cpg/back.png')} onClick={FromModal2to1}
                style={{ width: '24px', objectFit: 'cover', marginRight: '12px' }} />
              <h3 className="mb-0 fs-2">Details</h3>
            </div>
            <div className="right">
              <img src={toAbsoluteUrl('/media/cpg/close-modal.png')} style={{ width: '24px', objectFit: 'cover' }} />
            </div>
          </div>
          <div className="mt-6">
            <Alert variant="danger">
              Please check if the following details correspond with your upload and click to edit where you see fit.
            </Alert>
          </div>

          <img 
            className="image-fluid mx-auto d-block" 
            style={{ width: '100%', maxHeight: '200px', resize: "both", objectFit: "cover", borderRadius: "20px"}}
            src={toAbsoluteUrl('/media/cpg/uploadCardThumbnail.png')} 
            alt="upload image" 
          />
          
          <div className="d-flex flex-row justify-content-between my-4 px-10">
            <div>
              <p className="font-weight-light">Platform</p>
              <p style={{ fontWeight: 600, fontSize: 14 }}>{platform}</p>
            </div>

            <div>
              <p className="font-weight-light">Date and Time</p>
              <p style={{ fontWeight: 600, fontSize: 14 }}>01 - Dec - 2022 <span>&#183;</span> 11:00 PM</p>
            </div>
          </div>

          {/* <img src={toAbsoluteUrl('/media/cpg/close-modal.png')} style={{ width: '100%', height: '180px', objectFit: 'cover', borderRadius: '24px' }} /> */}
          <div className="row mt-6">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between align-items-center">
                <div className="col-sm-6">
                  <DonutChart
                    labels={impFieldsKeys}
                    series={impFieldsVal}
                    colors={colors}
                  />
                </div>

                <div className="container mb-4">
                  <div className="row w-100">
                    {Object.keys(importantFields).map((key: any, index: any) => (
                      <div className="col-4 my-2" key={key}>
                        <div className="mb-1" style={{ width: '16px', height: '16px', borderRadius: '50%', backgroundColor: `#${colors[index]}` }} />
                        <input className="text-center" type="number" value={importantFields[key]} onChange={(e) => updateImpFields(e.target.value, key, index)}
                            style={{ width: '60px', height: '40px', fontSize: '16px', color: '#333', margin: '4px 0', fontWeight: 500 }} />
                          <span style={{ fontSize: '11px', textTransform: 'capitalize', display: 'block', wordBreak: 'break-word' }}>
                            {key}
                          </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container pt-4" style={{ borderTop: '1px solid #D9D9D9' }}>
            <div className="row">
              {extractedData?.length > 0 && extractedData.map((field: any, index) => (
                <div className="col-6 my-2" key={index}>
                  <input className="text-center" type="text" value={field.value} onChange={(e) => updateExtracted(e.target.value, index)}
                    style={{ width: '60px', height: '40px', fontSize: '16px', color: '#333', margin: '4px 0', fontWeight: 600 }} />
                  <span style={{ fontSize: '11px', textTransform: 'capitalize', display: 'block', wordBreak: 'break-word' }}>
                    {splitWords(field.data_point)}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-end p-4">
            <button
              className='cancel-btn'
              type='button'
              onClick={toggleModal2}
              style={{ backgroundColor: '#3771C8' }}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary rounded rounded-3'
              type='button'
              onClick={prepareEditStats}
              style={{ backgroundColor: '#3771C8' }}
            >
              <img
                className='me-2'
                style={{ width: '16px', objectFit: 'cover' }}
                src={toAbsoluteUrl('/media/cpg/rocket-takeoff.png')}
                alt='l'
              />
              Upload
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div >
  )
}
