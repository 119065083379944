import { toAbsoluteUrl } from "../../../helpers";
import * as functions from "../../../../app/global/functions";

const classnames = (...classes: any) => classes.filter(Boolean).join(' ');


interface TileProps {
  Thumbnail: any;
  Impressions: number;
  rising: boolean;
  percentage?: number;
  platform?: any;
  sn?: string;
  PostLink?:string;
  EngagementRate?: number | string
}

function ImageTile({ Thumbnail, Impressions, rising, EngagementRate, platform, PostLink, sn }: TileProps) {
  return (
    <div className="d-flex flex-column justify-center mt-2 me-4">
      {platform
        ?
        <>
          <div className="d-flex align-items-center justify-content-center"
            style={{ width: '90px', height: '80px', backgroundColor: '#F4F4F4', borderRadius: '16px', objectFit: 'contain' }}>
            {platform && platform.SocialNetwork && <img src={toAbsoluteUrl(`/media/cpg/${platform.SocialNetwork !== 'Tiktok' ? platform.SocialNetwork : 'tiktok'}.png`)}
              className="rounded rounded-2" style={{ maxWidth: '48px', height: '48px' }}
            />}
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <span className="d-block text-black fw-bold fs-5">ER</span>
            <span className="d-block text-black fw-bolder fs-8"> {`${(platform?.value?.ER * 100).toFixed(2)}%`}</span>
          </div>
        </>
        :
        <>
          <a href = {PostLink} target = "_blank"><img src={Thumbnail || toAbsoluteUrl('/media/cpg/0.png')}
            className="rounded rounded-2" style={{ width: '88px', height: '104px', objectFit: 'cover' }}
          /></a>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <span className="d-block text-black fw-bolder fs-6">{functions.abvNum(Impressions)}</span>
            <span className={classnames("d-block text-center fs-8 px-1 py-1 fw-bolder rounded-4")}
              style={rising ? { backgroundColor: 'rgba(6, 214, 160, 0.1)', color: '#06D6A0' } : { backgroundColor: 'rgba(255, 0, 0, 0.1)', color: '#FF0000' }}
              // style={{ backgroundColor: 'rgba(233 255 243, 1)', color: '#49e28f' }}
            >
              {`${(Number(EngagementRate)*100).toFixed(2)}%`}
            </span>
          </div>
        </>
      }
    </div>
  )
}

export { ImageTile }
