import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import {CampaignSlice} from "../../app/modules/campaigns/_redux/campaign/Slice";
import { DashboardSlice } from '../../app/pages/dashboard/_redux/Slice';
import { InfluencerSlice } from '../../app/modules/influencer/_redux/Slice';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  campaign: CampaignSlice.reducer,
  dashboard: DashboardSlice.reducer,
  influencer: InfluencerSlice.reducer
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
