import * as requestFromServer from "./Crud";
import { CampaignSlice, callTypes } from "./Slice";

const { actions } = CampaignSlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignDetails = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchDetails(type, queryParams)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignDetails(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch details";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchStuff = (type, queryParams, reds = "false") => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchStuff(type, queryParams)
    .then(response => {
      let res = response.data;
      if (type === 'links') {
        // return res.success;
        dispatch(actions.campaignLinks(res.success));
      } else if (type === 'clicks') {
        if (reds === 'true') {
          return res.success;
        }
        dispatch(actions.campaignClicks(res.success));
      } else if (type === 'click_analytics') {
        dispatch(actions.campaignAnalytics(res.success));
      } else if (type === 'analytics') {
        dispatch(actions.campaignRealAnalytics(res.success));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't fetch details";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const reset_campaign_info = () => dispatch => {
  dispatch(actions.reset());
};

export const fetchCampaignStats = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchStats(type, queryParams)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignStats(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignEngagementRates = (id, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchEngagementRates(id, queryParams)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignEngagementRates(res));
    })
    .catch(error => {
      if (error.response.status == 403) {
        dispatch(actions.campaignEngagementRatesError());
      }
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignScore = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchScore(type, queryParams)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignScore(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignPosts = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchPosts(type, queryParams)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignPosts(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDefault = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchDafault()
    .then(response => {
      let res = response.data;
      dispatch(actions.default(res.success[0]));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignInfluencers = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchCampaignInfluencers(type, queryParams)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignInflencers(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchData = id => dispatch => {
  if (!id) {
    return dispatch(actions.DataFetched({ DataForEdit: undefined }));
  }
};

export const editDetails = (body, queryParams, type = 'details') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .editDetails(body, queryParams, type)
    .then(response => {
      let res = response.data;
      return res;
      // dispatch(actions.campaignScore(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const addCampaignStats = (data) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addCampaignStats(data)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteData = (queryParams, type = 'details') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDatas(queryParams, type)
    .then(response => {
      let res = response.data;
      return res;
      // dispatch(actions.campaignScore(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchCampaignStatsAll = (id, type) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchStatsAll(id, type)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignStatsAll(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignStatsPaginated = (id, type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchStatsPaginated(id, type, queryParams)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignStatsAll(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignStatsCount = (id, type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchStatsPaginated(id, type, queryParams)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignStatsCount(res.count));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};