import { MixedWidgetClicks, InfluencerTile, Brand, RadialChart, Gallery } from '../../../../_metronic/partials/widgets';
import React, { useState, useEffect } from 'react';
const scoregrad = {
  type: "gradient",
  gradient: {
    shade: "dark",
    type: "horizontal",
    gradientToColors: ["#FFDD55", "#eaff00"],
    stops: [0, 50, 100]
  },
  colors: ["#ed0000"],
};

const influencers = [
  {
    govtname: 'Cody Fisher',
    username: '@codyfisher',
    avi: 'influencer1',
  },
  {
    govtname: 'Cody Fisher',
    username: '@darlene',
    avi: 'influencer1',
  },
];

const images = ['influencer0', 'influencer0', 'influencer0', 'influencer0', 'influencer0'];
const brands = ['brand0', 'brand1', 'brand3', 'brand2', 'brand0'];

export default function StatSummary(data: any) {
  // console.log("sum sum", data);

  const [score, set_score] = useState(0);

  useEffect(() => {
    if (data.campaigns_activity && data.click_activity) {
        
        if (data.campaigns_activity.length > 0) {
          if (data.campaigns_activity[0].current_data && data.campaigns_activity[0].target_data) {
            // get(data.click_activity[0]);
            const { Impressions, EngagementRate } = data.campaigns_activity[0].current_data;
            const { target_impression, target_clicks, target_er } = data.campaigns_activity[0].target_data;
           
            var imp_score = (Impressions/target_impression) * 100
            var cl_score = (data.click_activity[0]/target_clicks) * 100
            var er_score = (EngagementRate/target_er) * 100;
  
            // console.log(imp_score, cl_score, er_score)
  
            var score = ((imp_score + cl_score + er_score) / 3);
  
            // console.log("Score!!!", score)
            set_score(Math.round(score > 100 ? 100 : score));
            
            
          }
      }
      

    
  }
  }, [data])
  return (
    <div className='row gy-5 g-xl-8 mt-4 mt-xl-0'>
      <div className='col-xl-3 col-lg-6'>
        <div className="mb-4 p-4" style={{ border: '2px solid #F4F4FA', borderRadius: '16px' }}>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span className="text-gray-600 fs-6 d-block">Status:</span>
            <span className="fw-bolder fs-6 d-flex align-items-center" style={{ color: '#06D6A0' }}>
              <div style={{ width: '12px', height: '12px', borderRadius: '50%', backgroundColor: '#06D6A0', marginRight: '4px' }} />
              {`${data?.influencerDetails?.Status} - ${data?.influencerDetails?.StatusCategory}` || 'N/A'}
            </span>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span className="text-gray-600 fs-6 d-block">Date Recruited:</span>
            <span className="fw-bolder fs-6 d-block text-black">{data?.influencerDetails?.DateRecruitingStarted || 'N/A'}</span>
          </div>
          {/* <div className="d-flex justify-content-between align-items-center mb-2">
            <span className="text-gray-600 fs-6 d-block">Date Joined</span>
            <span className="fw-bolder fs-6 d-block text-black">{data?.influencerDetails?.DateRecruitingStarted || 'N/A'}</span>
          </div> */}
        </div>
        {/* <div className="p-4 pt-0 mb-3" style={{ border: '2px solid #F4F4FA', borderRadius: '16px' }}>
          <h4 className="p-0 pt-6 text-xl text-black fw-medium mb-4">
            Top Posts
            <span className="font-light text-sm text-gray-400"> (by ER)</span>
          </h4>
          <div className="d-flex justify-content-between mb-12">
            {influencers.map((influencer: any, index: number) => (
              <InfluencerTile rising={true} key={index} {...influencer} />
            ))}
          </div>
          <div className="d-flex align-items-center my-2">
            <div className="me-4">
              <span className="ml-1 fs-6 fw-lighter">ER</span>
              <div className="d-flex align-items-center mb-4"
                style={{ backgroundColor: '#F4F4FA', borderRadius: '8px', padding: '6px', width: 'max-content' }}>
                <span className="fw-bold" style={{ fontSize: '12px' }}>26.32%</span>
              </div>
            </div>
            <div>
              <span className="d-block ml-1 fs-6 fw-lighter">AQS</span>
              <div className="d-flex align-items-center mb-4"
                style={{ backgroundColor: '#F4F4FA', borderRadius: '8px', padding: '6px', width: 'max-content' }}>
                <div style={{ width: '16px', height: '16px', borderRadius: '50%', backgroundColor: '#06D6A0', marginRight: '8px' }} />
                <span className="fw-bold" style={{ fontSize: '12px' }}>Good</span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center w-75">
            <div>
              <span className="d-block fs-6 fw-lighter">Platforms</span>
              <span className="d-block fw-bolder fs-4">1.6m</span>
            </div>
            <div>
              <span className="d-block fs-6 fw-lighter">Followers</span>
              <span className="d-block fw-bolder fs-4">970k</span>
            </div>
          </div>
        </div> */}
      </div>
      <div className='col-xl-3 col-lg-6'>
        <div className="p-4 pt-6" style={{ border: '2px solid #F4F4FA', borderRadius: '16px' }}>
          <h4 className="text-xl text-black fw-medium mb-4">Screenshots</h4>
          <Gallery images={images} />
        </div>
      </div>
      <div className='col-xl-3 col-lg-6'>
        <div className="p-4 pt-6" style={{ border: '2px solid #F4F4FA', borderRadius: '16px' }}>
          <h4 className="text-xl text-black fw-medium mb-4">Brands</h4>
          <div className="d-flex flex-wrap">
            {data && data.campaigns && data.campaigns.CampaignCollection.map((brand: any, index: number) => (
              <Brand logo={brand} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className='col-xl-3 col-lg-6'>
        <div className="w-full md:w-4/12 xl:w-2/1 p-4 pt-6" style={{ border: '2px solid #F4F4FA', borderRadius: '16px' }}>
          <h4 className="text-xl text-black fw-medium mb-4">Trend score</h4>
          <RadialChart
            width="340px"
            stroke={score}
            value=' '
            label=' '
            rate=' '
            oop={{ fill: scoregrad }}
          />
          <div className="mt-4" />
          {/* <MixedWidgetClicks
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='success'
            title="Trend over time"
            chartHeight='175px'
          /> */}
        </div>
      </div>
    </div>
  )
}
