/* eslint-disable jsx-a11y/anchor-is-valid */
// loading, ghost, routing(id)...
import { FC, useState, useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
// import * as actions from "./_redux/Actions";
import * as campaign_actions from "../../../app/modules/campaigns/_redux/campaign/Actions";
import {
  MixedWidgetClicks, ImageTile, Influencer, ChartImpressions, StatisticsWidgetX, RadialChart, ActionableUpdates
}
  from '../../../_metronic/partials/widgets'
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import * as functions from "../../global/functions"; 

const scoregrad = {
  type: "gradient",
  gradient: {
    shade: "dark",
    type: "horizontal",
    gradientToColors: ["#FFDD55", "#eaff00"],
    stops: [0, 50, 100]
  },
  colors: ["#ed0000"],
};


const DashboardWrapper = (props: any) => {
  let { id } = useParams<any>();
  // console.log(id)
  const dispatch = useDispatch<any>();
  const history = useHistory();

  const { campaignDetails, campaignStats, clicks, click_analytics, defaultCampaign, campaignScore, campaignEngagementRates, campaignEngagementRatesError } = useSelector(
    (state: any) => ({
      campaignDetails: state.dashboard.campaignDetails,
      clicks: state.campaign.clicks,
      click_analytics: state.campaign.click_analytics,
      defaultCampaign: state.dashboard.defaultCampaign,
      campaignStats: state.dashboard.campaignStats,
      campaignScore: state.dashboard.campaignScore,
      campaignEngagementRates: state.dashboard.campaignEngagementRates,
      campaignEngagementRatesError: state.dashboard.campaignEngagementRatesError,
    })
  );

  const [score, set_score] = useState<number>(0);

  useEffect(() => {
    // dispatch(actions.fetchDefault());
    // dispatch(actions.reset_campaign_info());
  }, []);


  useEffect(() => {
    if (defaultCampaign) {
      // dispatch(actions.fetchCampaignDetails('d', id || defaultCampaign.campaign_id));
      // dispatch(actions.fetchCampaignStats('d', id || defaultCampaign.campaign_id));
      // dispatch(actions.fetchCampaignEngagementRates(id ||defaultCampaign.campaign_id));
      // // dispatch(actions.fetchCampaignScore(id || defaultCampaign.campaign_id));
      // dispatch(campaign_actions.fetchStuff('clicks', id || defaultCampaign.campaign_id));
      // dispatch(campaign_actions.fetchStuff('click_analytics', `?mode=day&campaign_id=${id || defaultCampaign.campaign_id}`));
      // dispatch(campaign_actions.fetchStuff('analytics', `?campaign_id=${id || defaultCampaign.campaign_id}`));
    
    }
  }, [defaultCampaign]);


  useEffect(() => {
    
    var imp_score = 0;
    var cl_score = 0;
    var er_score = 0;
    var cpe_score = 0;
    

    if (campaignStats) {
      if (campaignStats.current_data && campaignStats.target_data) {
        const { Impressions, EngagementRate } = campaignStats.current_data;
        const { target_impression, target_clicks, target_er, target_cpe} = campaignStats.target_data;
        imp_score = (Impressions/target_impression) * 100
        er_score = (EngagementRate/target_er) * 100;
        cpe_score = (EngagementRate/target_cpe) * 100;
        cl_score = (clicks/target_clicks) * 100;

        var score = ((imp_score + cl_score + er_score + cpe_score) / 4);

        // console.log("Score!!!", score)
        set_score(Math.round(score));

         
      }
     

 
    }
     
    
  
  }, [campaignStats, clicks])

  return (
    <>
      {
       id &&
       <button className="btn btn-primary btn-sm rounded rounded-2 px-4 mb-10 " style={{ marginRight: 10 }}
          onClick={() => history.goBack()}
        >
        Go Back
      </button> 
      }
      <div className='row g-1 g-xl-1'>
        <div className='col-xl-12'>
          <StatisticsWidgetX campaignData={campaignDetails} clicks = {clicks} campaignStats={campaignStats} />
        </div>
      </div>

      <div className='row mt-2 g-xl-4 mt-xl-0'>
        <div className='col-xl-3 col-lg-6'>
          <div className="card p-2 pt-0 mb-2">
            <h5 className="p-0 pt-6 fs-5 text-black fw-bolder mb-3">
             Best Content
              <span className="font-light fs-8 text-sm text-gray-400"> (by ER)</span>
            </h5>
            {campaignEngagementRatesError
              ?
              <div className="d-flex flex-center text-center text-muted min-h-150px">
                Nothing to see,
                <br /><br />
                Best Content by ER unavailable
              </div>
              :
              <>
                {!campaignEngagementRates || !campaignEngagementRates.best_content
                  && <div className="">
                    <Skeleton count={2} />
                    <Skeleton count={1} height={80} />
                  </div>
                }
                {campaignEngagementRates && campaignEngagementRates.best_content &&
                  <div className="d-flex justify-content-between">
                    {campaignEngagementRates.best_content.map((content: any, index: number) => (
                      <ImageTile rising={true} key={index} {...content} />
                    ))}
                  </div>
                }
              </>

            }
          </div>

          <div className="card p-2 pt-0 mb-2">
            <span className="p-0 pt-6 fs-5 text-black fw-bolder mb-3">
              Low performing
              <span className="font-light fs-8 text-sm text-gray-400"> (by ER)</span>
            </span>

            {campaignEngagementRatesError
              ?
              <div className="d-flex flex-center text-center text-muted min-h-150px">
                Nothing to see,
                <br /><br />
                Low performing by ER unavailable
              </div>
              : <>
                {!campaignEngagementRates || !campaignEngagementRates.low_perfroming
                  && <div className="">
                    <Skeleton count={2} height={80} />
                  </div>
                }

                {campaignEngagementRates && campaignEngagementRates.low_perfroming &&
                  <div className="d-flex justify-content-between">
                    {campaignEngagementRates.low_perfroming.map((content: any, index: number) => (
                      <ImageTile key={index} {...content} />
                    ))}
                  </div>
                }
              </>
            }

          </div>

          <div className="card p-2 pt-0 mb-2">
            <span className="p-0 pt-6 fs-5 text-black fw-bolder mb-3">
              Top Platforms
              <span className="font-light fs-8 text-sm text-gray-400"> (by ER)</span>
            </span>
            {campaignEngagementRatesError
              ?
              <div className="d-flex flex-center text-center text-muted min-h-150px">
                Nothing to see,
                <br /><br />
                Top Platforms by ER not is unavailable
              </div>
              : <>
                {!campaignEngagementRates || !campaignEngagementRates.top_platforms
                  && <div className="">
                    <Skeleton count={2} />
                    <Skeleton count={1} height={80} />
                  </div>
                }
                {campaignEngagementRates && campaignEngagementRates.top_platforms &&
                  <div className="d-flex justify-content-between justify-content-lg-start">
                    {campaignEngagementRates.top_platforms.map((content: any, index: number) => (
                      <ImageTile key={index} {...content} platform={content} />
                    ))}
                  </div>
                }
              </>
            }
          </div>
        </div>
        <div className='col-xl-4 col-lg-6'>
          <div className="mt-0">
            <MixedWidgetClicks
              className='card-xxl-stretch-50 mb-5 mb-xl-8'
              chartColor='warning'
              title={`Clicks (${functions.abvNum(clicks)})`}
              chartHeight='175px'
              spaced
            />
          </div>

          <div className="mt-8">
            <ChartImpressions
              className='card-xxl-stretch-50 mb-5 mb-xl-8'
              chartColor='primary'
              title={`Engagement (${campaignStats && campaignStats.current_data ? functions.abvNum(campaignStats.current_data.Engagement) : 0})`}
              chartHeight='175px'
              spaced
            />
          </div>
        </div>
        <div className='col-xl-2 col-lg-6'>
          <div className="card w-full p-4 pt-6">
            <h5 className="text-xl text-black fw-medium mb-4">
              Top Influencers
              <span className="block font-light fs-8 text-sm text-gray-400"> (by ER)</span>
            </h5>
            {campaignEngagementRatesError
              ? <div className="d-flex flex-center text-center text-muted min-h-150px">
                Nothing to see,
                <br /><br />
                Top Influencers by ER not is unavailable
              </div>
              : <>
                {!campaignEngagementRates || !campaignEngagementRates.top_influencers
                  &&
                  <div className="">
                    <Skeleton count={2} />
                    <Skeleton count={3} height={80} />
                  </div>
                }
                {campaignEngagementRates && campaignEngagementRates.top_influencers &&
                  campaignEngagementRates.top_influencers.map((influencer: any, index: number) => (
                    <Influencer key={index} {...influencer} />
                  ))
                }
              </>
            }

          </div>
        </div>
        <div className='col-xl-3 col-lg-6'>
          <div className="card p-4 pt-6">
            <h5 className="text-xl text-black fw-medium mb-4">Campaign Score</h5>
            <RadialChart
              width="300px"
              stroke={Math.round(score)}
              value=' '
              label=' '
              rate=' '
              oop={{ fill: scoregrad }}
            />
            <h5 className="text-xl text-black fw-medium mb-1 mt-6" style = {{marginTop: 10}}>Actionable Updates</h5>
            <ActionableUpdates />
          </div>
        </div>
      </div>
    </>
  )
}


export { DashboardWrapper }
