import { toAbsoluteUrl } from "../../../helpers";

const classnames = (...classes: any) => classes.filter(Boolean).join(' ');
const items = [
  {
    type: 'pte',
    action: 'Campaign is losing ground in Brazil',
    actionText: 'Review Content',
    actionIcon: 'chevron-right',
    actionClick: () => console.log('clicked'),
  },
  {
    type: 'pte',
    action: 'Influncer Camila is not active.',
    actionText: 'Nudge Influencer',
    actionIcon: 'chevron-right',
    actionClick: () => console.log('clicked'),
  },
  {
    type: 'nte',
    action: 'Instagram stories has low engagement',
    actionText: 'Review Content',
    actionIcon: 'power-off',
    actionClick: () => console.log('clicked'),
  },
];

function ActionableUpdates() {
  return (
    <div className="mt-8 p-6" style={{ backgroundColor: '#F4F4FA', borderRadius: '16px' }}>
      {items.map((item, index) => (
        <div className="d-flex align-items-start py-5" style={{ borderBottom: '1px solid #E3E9FF' }} key={index}>
          <img src={toAbsoluteUrl(`/media/cpg/info-circle.png`)} style={{ width: '28px', height: '28px', marginRight: '16px' }} />
          <div>
            <p className="fw-bold mb-2">{item.action}</p>
            <div className="d-flex align-items-center">
              <span style={item.type === 'pte' ? { color: '#06D6A0' } : { color: '#FF0000' }} className="fw-bold me-2">
                {item.actionText}
              </span>
              <img src={toAbsoluteUrl(`/media/cpg/${item.actionIcon}.png`)}
                style={item.type === 'pte' ? { width: '12px', height: '18px' } : { width: '18px', height: '18px' }} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export { ActionableUpdates }
