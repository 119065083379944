import axios from "axios";

const URL = process.env.REACT_APP_API_URL || 'api'


export function findDatas(type, queryParams) {
  if (type === 'influencer') {
    return axios.get(`${URL}api/influencer/all${queryParams}`);
  } else if (type === 'influencer_details') {
    return axios.get(`${URL}api/influencer${queryParams}`);
  } else if (type === 'influencer_campaigns') {
    return axios.get(`${URL}api/influencer/campaigns${queryParams}`);
  } else if (type === 'socials') {
    return axios.get(`${URL}api/influencer/socials${queryParams}`);
  } else if (type === 'summary') {
    return axios.get(`${URL}api/influencer/summary${queryParams}`);
  } else if (type === 'demographics') {
    return axios.get(`${URL}api/influencer/demographics${queryParams}`);
  } else if (type === 'accounts_summary') {
    return axios.get(`${URL}api/influencer/accounts_summary${queryParams}`);
  } else if (type === 'contact') {
    return axios.get(`${URL}api/influencer/contact${queryParams}`);
  } else if (type === 'file') {
    return axios.get(`${URL}api/file/all${queryParams}`);
  }
}

export function fetchDetails(id) {
  return axios.get(`${URL}api/campaign?campaign_id=${id}`);
}

export function fetchStats(id) {
  return axios.get(`${URL}api/campaign/stats?campaign_id=${id}`);
}

export function fetchEngagementRates(id) {
  return axios.get(`${URL}api/campaign/engagement_rates?campaign_id=${id}`);
}

export function fetchScore(id) {
  return axios.get(`${URL}api/campaign/score?campaign_id=${id}`);
}

export function editDetails(body, id) {
  return axios.put(`${URL}api/campaign?campaign_id=${id}`, body);
}

export function uploadSs(payload) {
  return axios.post(`${URL}api/stats/upload`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function saveSs(payload) {
  return axios.post(`${URL}api/file`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function addCampaignStats(data) {
  return axios.post(`${URL}api/stats/`, data);
}