import { useEffect, useState } from 'react'
import Chart from "react-apexcharts";

function PieChart(props: any) {
  const [series, set_s] = useState([0, 0])
  const [options, set_options] = useState<any>({
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: ['Female', 'Male'],
    theme: {
      monochrome: {
        enabled: true
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 280
        },
      }
    }],
    legend: {
      show: false
    }
  });

  useEffect(() => {
    if (props) {
      // set_options((prevState: any) => ({
      //   ...prevState,
      //   series: [props.stroke]
      // }))
      set_s(props.stroke)
    }
  }, [props]);

  return (
    <div className="d-flex flex-column align-items-center jusify-content-center">
      <Chart options={options} series={series} type="pie" width={330} />
      <div className="d-flex justify-content-evenly align-items-center">
        <span className="d-flex align-items-center me-4">
          <div style={{
            width: '12px', height: '12px', borderRadius: '2px', marginRight: '2px',
            backgroundColor: 'rgb(55, 167, 252)'
          }} />
          Male
        </span>
        <span className="d-flex align-items-center me-4">
          <div style={{
            width: '12px', height: '12px', borderRadius: '2px', marginRight: '2px',
            backgroundColor: 'rgb(0, 143, 251)'
          }} />
          Female
        </span>
        {/* <span className="d-flex align-items-center me-4">
          <div style={{
            width: '12px', height: '12px', borderRadius: '2px', marginRight: '2px',
            backgroundColor: 'rgb(111, 192, 253)'
          }} />
          Others
        </span> */}
      </div>
    </div>
  )
}


export { PieChart }