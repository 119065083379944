import { createSlice } from "@reduxjs/toolkit";

const initialdatasState = {
  campaignDetails: null,
  campaignScore: null,
  campaignStats: null,
  campaignStatsAll: null,
  campaignStatsCount: null,
  campaignEngagementRates: null,
  campaignEngagementRatesError: false,
  campaignPosts: null,
  campaignInflencers: null,
  links: null,
  click_analytics: null,
  analytics: null,
  clicks: 0
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const CampaignSlice = createSlice({
  name: "campaigns",
  initialState: initialdatasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    reset: (state, action) => {
      // state.error = null;
      // state.campaignDetails = null;
      // state.campaignScore = null;
      // state.campaignDetails = null;
      // state.click_analytics = null
      // state.campaignPosts = null;
      // state.links = null;
      // state.campaignEngagementRates = null;
      // state.campaignInflencers = null;
      // state.campaignEngagementRatesError = false;
      // state.clicks = 0;
      state = initialdatasState;
    },
    disableLoad: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
    campaignClicks: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.clicks = action.payload;
    },
    campaignAnalytics: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.click_analytics = action.payload;
    },
    campaignRealAnalytics: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.analytics = action.payload;
    },
    campaignLinks: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.links = action.payload;
    },
    campaignDetails: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.campaignDetails = action.payload;
    },
    campaignPosts: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.campaignPosts = action.payload;
    },
    campaignStats: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.campaignStats = action.payload;
    },
    campaignStatsAll: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.campaignStatsAll = action.payload;
    },
    campaignStatsCount: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.campaignStatsCount = action.payload;
    },
    campaignEngagementRates: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.campaignEngagementRates = action.payload;
    },
    campaignScore: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.campaignScore = action.payload;
    },
    campaignEngagementRatesError: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.campaignEngagementRatesError = true;
    },
    campaignInflencers: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.campaignInflencers = action.payload;
    },
    

  }
});
