/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import InfiniteScroll from 'react-infinite-scroll-component';
import 'react-loading-skeleton/dist/skeleton.css'
import "react-datepicker/dist/react-datepicker.css";
import * as actions from "../_redux/campaign/Actions";
import { CardX } from '../../../../_metronic/partials/content/cards/CardX'
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import ModalLite from '../../../../_metronic/partials/modals/Modal';
import SearchInput from '../../influencer/components/Search';

const inputClassName = "appearance-none w-100 d-block px-3 h-48px border bg-gray-200 rounded placeholder-gray-500 fs-5";

const styles = {
  height: '54px',
  marginBottom: '16px',
  backgroundColor: '#F4F4FA',
  placeholderColor: '#000000',
}

const Campaigns = () => {
  const dispatch = useDispatch<any>();


  const [campaigns, set_campaign] = useState<any>([]);
  const [page, set_page] = useStateWithCallbackLazy<number>(1);
  const PAGE_SIZE = 10;

  const [isFetching, setIsFetching] = useState(false);
  var has_more = true;
  const [total, set_total] = useState<number>(0);
  const [modalIsOpen, toggleModal] = useState<boolean>(false);
  const [accountManager, setAccountManager] = useState<number>();
  const [campaignManager, setCampaignManager] = useState<number>();
  const [status, setStatus] = useState<string>('');
  const [fromDate, setFromDate] = useState<any>('');
  const [toDate, setToDate] = useState<any>('');

  useEffect(() => {
    fetchMoreData()
  }, []);

  const { campaign_data } = useSelector(
    (state: any) => ({
      campaign_data: state.dashboard.campaign_data
    }),
    shallowEqual
  );

  function fetchMoreData(page_no = 1) {

    if (!has_more) {
      setIsFetching(false);
      return;
    }

    // setIsFetching(true);
    dispatch(actions.fetchAllData('campaigns', `?page=${page_no}`)).then((data: any) => {
      setIsFetching(false);
      // console.log(data); return;
      if (data.success.count > 0) {
        set_total(data.success.total)
        set_page(
          (page_no: any) => page_no + 1,
          (s: any) => console.log('done')
        );
        set_campaign((prevState: any) => ([...prevState, ...data.success.CampaignCollection]));
      } else {
        has_more = false;
      }
    }).catch((error: any) => {
      has_more = false;
      setIsFetching(false);
      toast.error('Error occured while fetching campaigns');
    })

  }

  const submitFilter = () => {
    return 0;
  }
  return (
    <>
      <div className="d-flex justify-content-end align-items-center mb-10">
        <button onClick={() => toggleModal(true)}
          style={{ border: '1px solid #3771C8', width: '50px', height: '50px', marginRight: '24px', background: 'none', borderRadius: '12px' }}
        >
          <img src={toAbsoluteUrl('/media/cpg/sort.png')} style={{ height: '16px' }} />
        </button>
        <button onClick={() => console.log('clicks')}
          style={{ border: '1px solid #3771C8', width: '50px', height: '50px', marginRight: '24px', background: 'none', borderRadius: '12px' }}
        >
          <img src={toAbsoluteUrl('/media/cpg/filter.png')} style={{ height: '16px' }} />
        </button>
        {/* <input type="search" placeholder="Search Campaigns" className="px-4 rounded"
          style={{ backgroundColor: '#e4e5e9', border: 'none', height: '50px', minWidth: '200px' }}
        /> */}

        <SearchInput placeholder = {"Search campaigns"} />

      </div>
      <div
        className='row g-6 g-xl-9'
        // className="row"
        id="scrollableDiv"
        style={{
          height: window.innerHeight + document.documentElement.scrollTop,
          overflow: 'auto',
        }}

      >
        {campaigns.length === 0 &&
          new Array(12).fill('x').map((_, index) => (
            <div className="col-md-6 col-xl-3 mb-6" key={index}>
              <div className="bg-white p-6 rounded">
                <div className="d-flex">
                  <Skeleton circle width={40} height={40} inline />
                  <Skeleton inline />
                </div>
                <Skeleton count={3} height={60} baseColor="#fafafa" />
              </div>
            </div>
          ))
        }


        <InfiniteScroll
          dataLength={total}
          next={() => fetchMoreData(page)}
          hasMore={has_more}
          className="row"
          endMessage={
            <div className="col-12 text-center infinite-scroll-col">
              <div className="infinite-scroll-display">
                <div className="infinite-scroll-display__loading-end">
                  <p>No more campaign to show</p>
                </div>
              </div>
            </div>
          }
          loader={
            <div className="col-12 text-center infinite-scroll-col">
              <div className="infinite-scroll-display">
                <div className="infinite-scroll-display__loading-more">
                  <span className="loading-icon">
                    <svg
                      className="spinner"
                      width="24px"
                      height="24px"
                      viewBox="0 0 66 66"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        className="path"
                        fill="none"
                        strokeWidth="6"
                        strokeLinecap="round"
                        cx="33"
                        cy="33"
                        r="30"
                      ></circle>
                    </svg>
                  </span>
                  <p>{'Loading more campaigns...'}</p>
                </div>
              </div>
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          {campaigns && campaigns.map((item: any, index: any) => (
            <div className='col-md-6 col-xl-3' key={item.Campaign.CampaignId}>
              <CardX
                title={item.Campaign.CampaignName}
                description={item.Campaign?.Description || 'Campaign Description n/A'}
                status={item.Campaign.CampaignStatus}
                sdate={item.Campaign.StartDate}
                edate={item.Campaign.EndDate}
                campaignId={item.Campaign.CampaignId}
                LogoURL={item.Campaign.LogoURL}
              />
            </div>
          ))}
        </InfiniteScroll>
      </div>
      <ModalLite
        toggleModal={toggleModal}
        modalIsOpen={modalIsOpen}
        submit={submitFilter}
        title="Filter Campaigns"
      >
        <div>
          <input
            placeholder="Search Campaign manager"
            type="search"
            value={campaignManager}
            onChange={(e: any) => setCampaignManager(e.target.value)}
            className={inputClassName}
            style={styles}
          />
          <input
            placeholder="Search Account manager"
            type="search"
            value={accountManager}
            onChange={(e: any) => setAccountManager(e.target.value)}
            className={inputClassName}
            style={styles}
          />
          <select name="status" id="status" className={inputClassName} style={styles}
            value={status} onChange={(e) => setStatus(e.target.value)}
          >
            <option value="">Status</option>
            <option value="Offline">Offline</option>
            <option value="Online">Online</option>
          </select>

          <div className="my-4">
            <span className="d-block text-left text-xs mb-3">Timeframe</span>
            <div className="d-flex align-items-center justify-content-between">
              <div className="w-50 relative">
                <DatePicker
                  selected={fromDate}
                  onChange={(date) => setFromDate(date)}
                  selectsStart
                  placeholderText="From"
                  startDate={fromDate}
                  endDate={toDate} />
              </div>
              <div className="w-50 relative">
                <DatePicker
                  selected={toDate}
                  onChange={(date) => setToDate(date)}
                  selectsEnd
                  placeholderText="To"
                  startDate={fromDate}
                  endDate={toDate}
                  minDate={fromDate}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalLite>
    </>
  )
}

export { Campaigns }