import { toAbsoluteUrl } from "../../../helpers";

interface TileProps {
  images: string[];
}

function Gallery({ images }: TileProps) {
  console.log('images', images);
  return (
    <div className="flex flex-wrap justify-content-start">
      {/* {images.map((image, index) => (
        <img src={image ? toAbsoluteUrl(`/media/cpg/${image}.png`) : toAbsoluteUrl('/media/cpg/na.png')} key={index}
          className="rounded rounded-2" style={{ width: '95%', margin: '0 8px 8px 0', maxWidth: '140px', height: 'auto', objectFit: 'cover' }}
        />
      ))} */}
    </div>
  )
}

export { Gallery }
