/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import * as actions from "../_redux/campaign/Actions";

// Contracted deliverables

const classnames = (...classes: any) => classes.filter(Boolean).join(' ');
const Export = ({ onExport }: any) => <button onClick={(e: any) => onExport(e.target.value)}>Export</button>;

const CampaignStats = (props: any) => {

  const tableForms: any = {
    fbIgOrganic: [
      {
        required: false,
        key: 'influencerName',
        type: 'text',
        label: 'Influencer Name',
      },
      {
        required: false,
        key: 'platform',
        type: 'select',
        label: 'Platform',
        options: ['Select Platform', 'IG', 'TT', 'YT']
      },
      {
        required: false,
        type: 'select',
        key: 'postType',
        label: 'Post type',
        options: ['Select type', 'Reel', 'Post', 'Video', 'Story', 'Carousel'],
      },
      {
        required: true,
        type: 'number',
        key: 'numberOfPosts',
        label: 'Number of posts',
      },
      {
        required: true,
        type: 'text',
        key: 'verticals',
        label: 'Verticals',
      },
      {
        required: true,
        type: 'text',
        key: 'niche',
        label: 'Niche',
      },
      {
        required: true,
        type: 'text',
        key: 'coreAudience',
        label: 'Core Audience',
      },
      {
        required: true,
        type: 'text',
        key: 'followers',
        label: 'Followers',
      },
      {
        required: true,
        type: 'text',
        key: 'estimatedReach',
        label: 'Estimated Reach',
      },
      {
        required: true,
        type: 'text',
        key: 'costPerDeliverable',
        label: 'Cost per Deliverable',
      },
      {
        required: true,
        type: 'text',
        key: 'feePaid',
        label: 'Cost/Fee paid',
      },
      {
        required: true,
        type: 'text',
        key: 'paidUsage',
        label: 'Paid Usage/Terms',
      },

      {
        required: true,
        type: 'text',
        key: 'messaging',
        label: 'Messaging',
      },
      {
        required: true,
        type: 'text',
        key: 'er',
        label: 'ER - 5%',
      },
      {
        required: false,
        type: 'text',
        key: 'linkToContent',
        label: 'Link to content posted',
      },
      {
        required: false,
        type: 'text',
        key: 'username',
        label: 'Username',
      },
      {
        required: false,
        type: 'date',
        key: 'mediaCreatedDate',
        label: 'Media created date',
      },
      {
        required: false,
        type: 'text',
        key: 'mediaURL',
        label: 'Media URL',
      },
      {
        required: false,
        type: 'text',
        key: 'mediaThumbnailURL',
        label: 'Media thumbnail URL',
      },
      {
        required: false,
        type: 'text',
        key: 'mediaCaption',
        label: 'Media caption',
      },
      {
        required: false,
        type: 'text',
        key: 'mediaType',
        label: 'Media type ',
      },
      {
        required: false,
        type: 'text',
        key: 'mediaLikes',
        label: 'Media likes',
      },
      {
        required: false,
        type: 'text',
        key: 'mediaComments',
        label: 'Media comments',
      },
      {
        required: false,
        type: 'text',
        key: 'mediaEngagements',
        label: 'Media engagements',
      },
      {
        required: false,
        type: 'text',
        key: 'mediaImpressions',
        label: 'Media impressions',
      },
      {
        required: false,
        type: 'text',
        key: 'mediaReach',
        label: 'Media reach',
      },
      {
        required: false,
        type: 'text',
        key: 'mediaUniqueSaves',
        label: 'Media unique saves',
      },
      {
        required: false,
        type: 'text',
        key: 'videoViews',
        label: 'Video views',
      },
      {
        required: false,
        type: 'text',
        key: 'reelComments',
        label: 'Reel comments',
      },
      {
        required: false,
        type: 'text',
        key: 'reelLikes',
        label: 'Reel likes',
      },
      {
        required: false,
        type: 'text',
        key: 'reelPlays',
        label: 'Reel plays',
      },
      {
        required: false,
        type: 'text',
        key: 'reelSavedTimes',
        label: 'Reel saved times',
      },
      {
        required: false,
        type: 'text',
        key: 'reelSharedTimes',
        label: 'Reel shared times',
      },
      {
        required: false,
        type: 'text',
        key: 'reelTotalInteractions',
        label: 'Reel total interactions',
      },
      {
        required: false,
        type: 'text',
        key: 'cpc',
        label: 'CPC',
      },
      {
        required: false,
        type: 'text',
        key: 'cpm',
        label: 'CPM',
      },
      {
        required: false,
        type: 'text',
        key: 'cpe',
        label: 'CPE',
      },
    ],
    tiktokOrganic: [
      {
        required: false,
        key: 'influencerName',
        type: 'text',
        label: 'Influencer Name',
      },
      {
        required: false,
        key: 'platform',
        type: 'select',
        label: 'Platform',
        options: ['Select Platform', 'IG', 'TT', 'YT']
      },
      {
        required: false,
        type: 'select',
        key: 'postType',
        label: 'Post type',
        options: ['Select type', 'Reel', 'Post', 'Video', 'Story', 'Carousel'],
      },
      {
        required: true,
        type: 'text',
        key: 'numberOfPosts',
        label: 'Number of posts',
      },
      {
        required: true,
        type: 'text',
        key: 'verticals',
        label: 'Verticals',
      },
      {
        required: true,
        type: 'text',
        key: 'niche',
        label: 'Niche',
      },
      {
        required: true,
        type: 'text',
        key: 'coreAudience',
        label: 'Core Audience',
      },
      {
        required: true,
        type: 'text',
        key: 'followers',
        label: 'Followers',
      },
      {
        required: true,
        type: 'text',
        key: 'estimatedReach',
        label: 'Estimated Reach',
      },
      {
        required: true,
        type: 'text',
        key: 'costPerDeliverable',
        label: 'Cost per Deliverable',
      },
      {
        required: true,
        type: 'text',
        key: 'feePaid',
        label: 'Cost/Fee paid',
      },
      {
        required: true,
        type: 'text',
        key: 'paidUsage',
        label: 'Paid Usage/Terms',
      },
      {
        required: true,
        type: 'text',
        key: 'messaging',
        label: 'Messaging',
      },
      {
        required: true,
        type: 'text',
        key: 'er',
        label: 'ER - 5%',
      },
      {
        required: false,
        type: 'text',
        key: 'linkToContent',
        label: 'Link to content posted',
      },
      {
        required: false,
        type: 'date',
        key: 'datePublished',
        label: 'Date published',
      },
      {
        required: false,
        type: 'text',
        key: 'videoViews',
        label: 'Video views',
      },
      {
        required: false,
        type: 'text',
        key: 'profileViews',
        label: 'Profile views',
      },
      {
        required: false,
        type: 'text',
        key: 'likes',
        label: 'Likes',
      },
      {
        required: false,
        type: 'text',
        key: 'comments',
        label: 'Comments',
      },
      {
        required: false,
        type: 'text',
        key: 'shares',
        label: 'Shared',
      },
      {
        required: true,
        type: 'text',
        key: 'cpm',
        label: 'CPM',
      },
      {
        required: true,
        type: 'text',
        key: 'cpc',
        label: 'CPC',
      },
      {
        required: true,
        type: 'text',
        key: 'cpe',
        label: 'CPE',
      },
    ],
    youtubeOrganic: [
      {
        required: false,
        key: 'influencerName',
        type: 'text',
        label: 'Influencer Name',
      },
      {
        required: false,
        key: 'platform',
        type: 'select',
        label: 'Platform',
        options: ['Select Platform', 'IG', 'TT', 'YT']
      },
      {
        required: false,
        type: 'select',
        key: 'postType',
        label: 'Post type',
        options: ['Select type', 'Reel', 'Post', 'Video', 'Story', 'Carousel'],
      },
      {
        required: true,
        type: 'text',
        key: 'numberOfPosts',
        label: 'Number of posts',
      },
      {
        required: true,
        type: 'text',
        key: 'verticals',
        label: 'Verticals',
      },
      {
        required: true,
        type: 'text',
        key: 'niche',
        label: 'Niche',
      },
      {
        required: true,
        type: 'text',
        key: 'coreAudience',
        label: 'Core Audience',
      },
      {
        required: true,
        type: 'text',
        key: 'followers',
        label: 'Followers',
      },
      {
        required: true,
        type: 'text',
        key: 'estimatedReach',
        label: 'Estimated Reach',
      },
      {
        required: true,
        type: 'text',
        key: 'costPerDeliverable',
        label: 'Cost per Deliverable',
      },
      {
        required: true,
        type: 'text',
        key: 'feePaid',
        label: 'Cost/Fee paid',
      },
      {
        required: true,
        type: 'text',
        key: 'paidUsage',
        label: 'Paid Usage/Terms',
      },
      {
        required: true,
        type: 'text',
        key: 'messaging',
        label: 'Messaging',
      },
      {
        required: true,
        type: 'text',
        key: 'er',
        label: 'ER - 5%',
      },
      {
        required: false,
        type: 'text',
        key: 'linkToContent',
        label: 'Link to content posted',
      },
      {
        required: false,
        type: 'date',
        key: 'datePublished',
        label: 'Date published',
      },
      {
        required: false,
        type: 'text',
        key: 'watchTime',
        label: 'Watch time',
      },
      {
        required: false,
        type: 'text',
        key: 'subscribers',
        label: 'Subscribers',
      },
      {
        required: false,
        type: 'text',
        key: 'averageViewDuration',
        label: 'Average View Duration',
      },
      {
        required: false,
        type: 'text',
        key: 'averagePercentageViewed',
        label: 'Average Percentage Viewed',
      },
      {
        required: false,
        type: 'text',
        key: 'clickThroughRate',
        label: 'Click Through Rate',
      },
      {
        required: true,
        type: 'text',
        key: 'uniqueViewers',
        label: 'Unique Viewers',
      },
    ],
    actualDeliveryOrganic: [
      {
        required: false,
        key: 'phase',
        type: 'select',
        label: 'Phase',
        options: ['Select Phase', 'Phase 1', 'Phase 2', 'Phase 3',]
      },
      {
        required: false,
        key: 'influencer',
        type: 'text',
        label: 'Influencer',
      },
      {
        required: false,
        type: 'select',
        key: 'deliverable',
        label: 'Deliverable',
        options: ['Select type', 'Reel', 'Post', 'Video', 'Story', 'Carousel'],
      },
      {
        required: false,
        type: 'text',
        key: 'linkToContent',
        label: 'Link to content posted',
      },
      {
        required: false,
        type: 'date',
        key: 'datePublished',
        label: 'Date published',
      },
      {
        required: true,
        type: 'number',
        key: 'impressions',
        label: 'Impressions',
      },
      {
        required: true,
        type: 'number',
        key: 'videoViews',
        label: 'Video views',
      },
      {
        required: false,
        type: 'text',
        key: 'reach',
        label: 'Reach',
      },
      {
        required: false,
        type: 'text',
        key: 'likes',
        label: 'Likes',
      },
      {
        required: false,
        type: 'text',
        key: 'comments',
        label: 'Comments',
      },
      {
        required: false,
        type: 'text',
        key: 'saves',
        label: 'Saves',
      },
      {
        required: false,
        type: 'text',
        key: 'reaction',
        label: 'Reaction',
      },
      {
        required: false,
        type: 'text',
        key: 'shares',
        label: 'Shares',
      },
      {
        required: false,
        type: 'text',
        key: 'interactions',
        label: 'Interactions',
      },
      {
        required: true,
        type: 'text',
        key: 'totalEngagements',
        label: 'Total Engagements',
      },
      {
        required: true,
        type: 'text',
        key: 'linkClicks',
        label: 'Link Clicks',
      },
      {
        required: false,
        type: 'select',
        key: 'ChannelBreakdowc',
        label: 'Channel Breakdown',
        options: ['Select Channel', 'Instagram', 'TikTok', 'Facebook', 'Youtube'],
      },

    ],
    estimatedDeliveryOrganic: [
      {
        required: false,
        key: 'phase',
        type: 'select',
        label: 'Phase',
        options: ['Select Phase', 'Phase 1', 'Phase 2', 'Phase 3',]
      },
      {
        required: false,
        key: 'influencer',
        type: 'text',
        label: 'Influencer',
      },
      {
        required: false,
        type: 'select',
        key: 'deliverable',
        label: 'Deliverable',
        options: ['Select deliverable', 'Reel', 'Post', 'Video', 'Story', 'Carousel'],
      },
      {
        required: false,
        type: 'number',
        key: 'quantity',
        label: 'Quantity',
      },
      {
        required: false,
        type: 'text',
        key: 'linkToProfile',
        label: 'Link to profile',
      },
      {
        required: false,
        type: 'select',
        key: 'vertical',
        label: 'Vertical',
        options: ['Select vertical', 'Lifestyle', 'Educational', 'Health & Wellness', 'Humour', 'Entertainment'],
      },
      {
        required: false,
        type: 'text',
        key: 'niche',
        label: 'Niche',
      },
      {
        required: false,
        type: 'text',
        key: 'coreAudienceDemo',
        label: 'Core Audience Demo',
      },
      {
        required: true,
        type: 'number',
        key: 'socialFollowing',
        label: 'Social following',
      },
      {
        required: false,
        type: 'text',
        key: 'estimatedReach',
        label: 'Estimated Reach',
      },
      {
        required: false,
        type: 'text',
        key: 'estimatedImpression',
        label: 'Estimated Impression',
      },
      {
        required: false,
        type: 'text',
        key: 'estimatedER',
        label: 'Estimated ER',
      },
      {
        required: false,
        type: 'number',
        key: 'costPerDeliverable',
        label: 'Cost Per Deliverable',
      },
      {
        required: false,
        type: 'number',
        key: 'totalInvestment',
        label: 'Total Investment',
      },
      {
        required: false,
        type: 'text',
        key: 'paidUsage',
        label: 'Paid Usage/Terms',
      },
      {
        required: false,
        type: 'text',
        key: 'consolidatedList',
        label: 'Consolidated List',
      },
      {
        required: false,
        type: 'text',
        key: 'influencerVertical',
        label: 'Influencer Vertical',
      },
      {
        required: false,
        type: 'select',
        key: 'niche',
        label: 'Niche',
        options: ['Select niche', 'Lifestyle', 'Educational', 'Health & Wellness', 'Humour', 'Entertainment'],
      },

      {
        required: false,
        type: 'select',
        key: 'ChannelBreakdown',
        label: 'Channel Breakdown',
        options: ['Select Channel', 'Instagram', 'TikTok', 'Facebook', 'Youtube'],
      },
      {
        required: false,
        type: 'select',
        key: 'costBreakdown',
        label: 'Cost Breakdown',
        options: ['Select Channel', 'Instagram', 'TikTok', 'Facebook', 'Youtube'],
      },
      {
        required: false,
        type: 'text',
        key: 'ratio',
        label: 'Ratio',
      },
      {
        required: false,
        type: 'text',
        key: 'qty',
        label: 'Qty',
      },
      {
        required: false,
        type: 'text',
        key: 'totalCost',
        label: 'Total cost',
      },
      {
        required: false,
        type: 'text',
        key: 'costPer',
        label: 'Cost per',
      },
    ]
  }

  let { id } = useParams<any>();
  const dispatch = useDispatch<any>();
  const [view, setView] = useState<string>('organic');
  const [editing, setEditing] = useState<boolean>(false);
  const [formFields, updatefields] = useState<any>({});
  const [tableColumns, updateTableColumns] = useState<any>([]);
  const [tableData, updateTableData] = useState<any>([]);
  const [currentFormName, setCurrentFormName] = useState('fbIgOrganic');
  const [currentForm, setCurrentForm] = useState(tableForms[currentFormName]);
  const [skip, setSkip] = useState<number>(0);
  const [pageLimit, setPageLimit] = useState(10);

  const { campaignStatsAll, campaignStatsCount } = useSelector(
    (state: any) => ({
      campaignStatsAll: state.campaign.campaignStatsAll,
      campaignStatsCount: state.campaign.campaignStatsCount,
    })
  );

  useEffect(() => {
    dispatch(actions.reset_campaign_info());
    dispatch(actions.fetchCampaignDetails(id));
    dispatch(actions.fetchCampaignEngagementRates(id));
    dispatch(actions.fetchCampaignScore(id));
    dispatch(actions.fetchCampaignStatsCount(id, currentFormName, '&count=1'));
  }, []);

  useEffect(() => {
    dispatch(actions.fetchCampaignStatsAll(id, currentFormName));
    dispatch(actions.fetchCampaignStatsCount(id, currentFormName, '&count=1'));
  }, [currentForm]);

  useEffect(() => {
    InstTable();
  }, [campaignStatsAll, pageLimit]);

  const InstTable = () => {
    const cols: any = [];
    const rows: any = [];
    let row: any = {};
    let addedRows = false;
    if (campaignStatsAll && campaignStatsAll.length > 0) {
      campaignStatsAll.map((stat: any, index: number) => {
        row = {}; // clears row of any data in it
        Object.keys(stat.data).map((key: any) => {
          if (!addedRows) {
            cols.push({
              name: stat.data[key].label,
              selector: stat.data[key].label,
            });
          }
          const label = stat.data[key].label;
          const value = stat.data[key].value;
          row[label] = value || 'n/A';
        });
        addedRows = true;
        rows.push(row);
      });
    }
    setTimeout(() => {
      updateTableColumns(cols);
      updateTableData(rows);
    }, 200)
  }

  const fields: any = {};

  const ddOptions: any = [
    { value: 'estimatedDeliveryOrganic', label: 'Estimated Delivery', img: 'overall' },
    { value: 'fbIgOrganic', label: 'Facebook | Instagram', img: 'facebook' },
    { value: 'tiktokOrganic', label: 'TikTok', img: 'sm-tiktok' },
    { value: 'youtubeOrganic', label: 'Youtube', img: 'sm-yt' },
    { value: 'actualDeliveryOrganic', label: 'Actual Delivery', img: 'overall' },
  ];

  const updateFormField = (option: any, value: any) => {
    const fold = { ...option, value };
    const fieldsClone = formFields;
    fieldsClone[option['key']] = fold;
    updatefields(fieldsClone);
  }

  const sendCampaignStats = () => {
    const body: any = {};
    body.data = formFields;
    body.campaign_id = id;
    body.type = currentFormName;

    currentForm.map((item: any) => {
      if (!formFields[item.key]) {
        const keyy = item.key;
        formFields[keyy] = item
      }
    });
    Object.keys(formFields).map((key) => {
      if (formFields[key].required && !formFields[key].value) {
        return toast.error(formFields[key].label + ' is required');
      }
    });

    dispatch(actions.addCampaignStats(body)).then((res: any) => {
      setEditing(!editing)
      toast.success('Successfully added campaign info');
      updatefields({});
      dispatch(actions.fetchCampaignStats(id));
      dispatch(actions.fetchCampaignStatsAll(id, currentFormName));
    }).catch(() => {
      setEditing(!editing)
      toast.error('Error occured while updating campaign info');
    });
  }

  const updateFormTable = (key: string) => {
    updatefields({});
    setEditing(false);
    setCurrentFormName(key);
    setCurrentForm(tableForms[key]);
  }

  const CustomOption = (props: any) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div className="px-2 py-4" onClick={() => updateFormTable(data.value)}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}>
        <button className="dropdown-item" ref={innerRef} {...innerProps}>
          <img src={toAbsoluteUrl(`/media/cpg/${data.img}.png`)} style={{ width: '24px', marginRight: '12px' }} />
          {data.label}
        </button>
      </div>
    )
  };

  function convertArrayOfObjectsToCSV(array: any) {
    let result: any;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(tableData[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item: any) => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        // eslint-disable-next-line no-plusplus
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array: any) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  const handlePageChange = async () => {
    const skipper = skip + 1;
    setSkip(skipper);
    await dispatch(actions.fetchCampaignStatsPaginated(id, currentFormName, `&limit=${pageLimit}&skip=${skipper}`));
    InstTable();
  }

  const handlePerRowsChange = async (limit = 10) => {
    setPageLimit(limit);
    await dispatch(actions.fetchCampaignStatsPaginated(id, currentFormName, `&limit=${limit}&skip=${skip}`));
    InstTable();
  };

  return (
    <>
      <div className='row g-4 mt-2'>
        {editing &&
          <div className="card my-4 p-4 p-lg-6">
            <h1 className="fs-2 fw-bolder">Create/Edit Entry</h1>
            <div className="row">
              {Object.keys(currentForm).map((key: any, index: number) => (
                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6" key={index}>
                  <div className="my-3">
                    <label className="d-block mb-2">
                      {currentForm[key].label}
                      <span className="text-danger fs-4">{currentForm[key].required && '*'}</span>
                    </label>
                    {currentForm[key].type === 'select'
                      ? <select style={{ border: '2px solid #D9D9D9', borderRadius: '8px', height: '36px', padding: '4px 8px', width: '95%' }}
                        onChange={(e) => updateFormField(currentForm[key], e.target.value)}
                      >
                        {currentForm[key].options.map((option: any, index: number) => (
                          <option key={index} value={option}>{option}</option>
                        ))}
                      </select>
                      : <input
                        style={{ border: '2px solid #D9D9D9', borderRadius: '8px', height: '36px', padding: '4px 8px', width: '95%' }}
                        type={currentForm[key].type} value={fields[key]} placeholder={`Enter ${currentForm[key].label}`}
                        onChange={(e) => updateFormField(currentForm[key], e.target.value)}
                      />
                    }
                  </div>
                </div>
              ))}
            </div>
            <button className="btn btn-lg btn-info mt-6 d-block ms-auto my-6" onClick={sendCampaignStats}>Submit</button>
          </div>
        }
        <div className="py-6 px-4 card">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className='d-flex align-items-center'>
              <button className={classnames((view === 'organic' ? "btn-primary active text-white" : "text-gray-600 btn-link"), "btn rounded rounded-2 px-4 me-4")}
                onClick={() => setView('organic')}
              >
                <img src={toAbsoluteUrl('/media/cpg/chart-line-up.png')} style={{ width: '16px', marginRight: '4px' }} />
                Organic
              </button>
              <button className={classnames((view === 'paid' ? "btn-primary active text-white" : "text-gray-600 btn-link"), "btn rounded rounded-2 px-4")}
                onClick={() => setView('paid')}
              >
                <img src={toAbsoluteUrl('/media/cpg/paid.png')} style={{ width: '16px', marginRight: '4px' }} />
                Paid
              </button>
            </div>
            <div className="d-flex align-items-center">
              <div style={{ width: '220px', maxWidth: '220px', marginRight: '20px' }}>
                <Select components={{ Option: CustomOption }} options={ddOptions} />
              </div>
              <button className="btn btn-primary uppercase me-4" onClick={() => downloadCSV(tableData)}>
                <span className='fw-bold fs-7'>Export</span>
              </button>
              <button className="btn btn-sm btn-warning btn-sm" onClick={() => setEditing(!editing)}>
                <span className='fw-bold fs-7 text-black'>Add New</span>
                <img src={toAbsoluteUrl('/media/cpg/plus.png')} style={{ width: '10px', marginLeft: '6px' }} />
              </button>
            </div>
          </div>
          <div className="mt-6">
            <DataTable
              columns={tableColumns}
              data={tableData}
              pagination
              paginationServer
              paginationTotalRows={campaignStatsCount}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
            {/* <div className="d-flex">
              <button onClick={() => handlePrevNext('prev')}>Prev</button>
              <button onClick={() => handlePrevNext('next')}>Next</button>
              <select defaultValue={pageLimit} onChange={(e: any) => handleRowsChange(e.target.value)}>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
                <option value={30}>30</option>
              </select>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export { CampaignStats }
