import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
// import { CampaignDetails } from '../modules/campaigns/components/CampaignDetails'
import { CampaignSummary } from '../modules/campaigns/components/CampaignSummary'
// import { InfluencerDetails } from '../modules/influencer/InfluencerDetails'
import { Campaigns } from '../modules/campaigns/components/Campaigns'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { InfluencerStats } from '../modules/influencer/InfluencerStats'

export function PrivateRoutes() {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const CampainPage = lazy(() => import('../modules/campaigns/CampaignPage'));
  const InfluencerPage = lazy(() => import('../modules/influencer/InfluencerPage'));
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'));

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/app/dashboard' component={InfluencerStats} />
        <Route path='/app/campaign/summary/:id' component={CampaignSummary} />
        <Route path='/app/campaign/:id' component={DashboardWrapper} />
        <Route path='/app/campaigns' component={Campaigns} />
        <Route path='/app/influencers/:id/stats' component={InfluencerStats} />
        <Route path='/app/influencers/:id' component={InfluencerStats} />
        <Route path='/app/influencers' exact component={InfluencerPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Redirect from='/auth' to='/app/campaigns' />
        <Redirect from='/dashboard' to='/app/dashboard' />
        <Redirect exact from='/' to='/app/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
