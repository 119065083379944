/* eslint-disable jsx-a11y/anchor-is-valid */
// 618220 - have both current and target
import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { toAbsoluteUrl } from '../../../helpers'
import { RadialChart } from '../custom/RadialChart2'
import * as functions from "../../../../app/global/functions";


type Props = {
  className?: string
  svgIcon?: string
  iconColor?: string
  title?: string
  description?: string
  influencerDetails: any;
  campaignStats: any;
  click_activity: any;
  campaigns_activity: any;
}

const InfluencerStatsTopWidget: React.FC<Props> = ({
  className,
  campaignStats,
  click_activity,
  influencerDetails,
  campaigns_activity,
}) => {

  const [chartData, setChartData] = useState<any>([
    {
      stroke: 0,
      value: 0,
      rate: 0,
      meta: '0',
      label: "Impressions",
    },
    {
      stroke: 0,
      value: 0,
      meta: '0',
      rate: 0,
      label: "Clicks",
    },
    {
      stroke: 0,
      meta: '0',
      value: 0,
      rate: 0,
      label: "ER",
    },
  ]);

  const calcAvg = (a: any, b: any) => {
    if (a && b) {
      const numb = Number((a / Number(b))) * 100
      return numb.toFixed(2);
    }
    return 0.00;
  }

  const updateChart = (sum_clicks: number) => {
    let clone = chartData;
    // console.log(campaigns_activity); return;
    if (campaigns_activity[0].current_data && campaigns_activity[0].target_data) {
      const { Impressions, EngagementRate } = campaigns_activity[0].current_data;
      const { target_impression, target_clicks, target_er } = campaigns_activity[0].target_data;
      clone.map((clone: any) => {
        if (clone?.label === 'Impressions') {
          clone.stroke = calcAvg(Impressions, target_impression);
          clone.value = Impressions.toString();
          clone.rate = `${functions.abvNum(Impressions)} / ${functions.abvNum(target_impression)}`;
          clone.meta = `${functions.abvNum(Impressions)}`;
        }
        if (clone.label === 'Clicks') {
          clone.stroke = calcAvg(sum_clicks, target_clicks);
          clone.value = sum_clicks?.toString();
          clone.rate = `${functions.abvNum(sum_clicks)} / ${functions.abvNum(target_clicks)}`;
          clone.meta = `${functions.abvNum(sum_clicks || 0)}`;
        }
        if (clone.label === 'ER') {
          clone.stroke = calcAvg(EngagementRate, target_er);
          clone.value = EngagementRate?.toString();
          clone.rate = `${functions.abvNum(EngagementRate)} / ${functions.abvNum(target_er)}`;
          clone.meta = `${EngagementRate.toFixed(2)} %`;
        }

      });

      clone.done = true;
      // console.log(clone);
      setChartData([...clone]);
    } else {
      clone.done = true;
    }
  }





  useEffect(() => {
    // console.log("UPDATEEEEEE", campaigns_activity, click_activity)
    var new_arr: any = [];
      // console.log("campaigns_activity", campaigns_activity, click_activity);
       
      if (campaigns_activity && click_activity) {
        
        if (campaigns_activity.length > 0) {
          if (campaigns_activity[0]?.current_data && campaigns_activity[0]?.target_data) {
            updateChart(click_activity[0]);
          }
        }
        
  
      
    }
  }, [campaigns_activity, click_activity]);

 
  return (
    <>
      {/* {!campaignData || !campaignData.CampaignId
        && <Skeleton height={70} count={3} />
      } */}
      {/* {campaignData && campaignData.CampaignId && */}
      <div className={`card ${className}`}>
        <div className='card-body row'>
          <div className="col-xl-4">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-start">
                <img src={influencerDetails?.LogoURL || toAbsoluteUrl('/media/cpg/influencer1.png')} alt="@"
                  style={{ width: '185px', height: '185px', marginRight: '12px', borderRadius: '18px', display: 'block' }} />
                <div className='ml-2 pt-1'>
                  <h5 className="fw-bold mb-0" style={{ fontSize: '20px' }}>{influencerDetails.PublisherName}</h5>
                  <span className="d-block fs-6 fw-lighter text-muted mb-4">{influencerDetails.Country}</span>
                  <div className="mb-1">
                    {/* <span className="ml-1 fs-6 fw-lighter">AQS</span>
                    <img src={toAbsoluteUrl('/media/cpg/circle-info.png')} style={{ width: '16px', height: '16px', marginLeft: '6px' }} /> */}
                  </div>
                  <span className="d-flex align-items-center mb-4"
                    style={{ backgroundColor: '#F4F4FA', borderRadius: '8px', padding: '4px', width: 'max-content' }}>
                    <div style={{ width: '12px', height: '12px', borderRadius: '50%', backgroundColor: '#06D6A0', marginRight: '8px' }} />
                    <span style={{fontSize: '12px', fontWeight: 700}}>{influencerDetails.PublisherStatus}</span>
                  </span>
                  <span className="d-block fs-6 fw-lighter">Total Subscribers</span>
                  <span className="d-block fw-bolder fs-3">{functions.abvNum(Number(influencerDetails.TotalSubscribers))}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
              {chartData &&
                chartData.map((chart: any, index: number) => (
                  <RadialChart key={index} {...chart} />
                ))}
            </div>
          </div>
        </div>
      </div>
      {/* } */}
    </>
  )
}

export { InfluencerStatsTopWidget }
